import React, {useState, useRef} from 'react'
import HomeIntro from '../components/page-components/Homepage/HomeIntro'
import { graphql } from 'gatsby'
import PlayIcon from '../images/play.inline.svg'
import {Styling} from '../styles/pages/about.styled'
import parse from 'html-react-parser';
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import Seo from '../components/global/seo'


export default function About({data}) {
  // const [isPlaying, setIsPlaying] = useState(false)
  // const videoRef = useRef();
  // const handlePlayVideo = () => {
  //     const video = videoRef.current;
  //     if(isPlaying === false){
  //         video.play()
  //         setIsPlaying(true)
  //     }else{
  //         video.pause()
  //         setIsPlaying(false)
  //     }
  // }

  const about = data.wpPage.about_fields

    return (
        <Styling>
            <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
            <HomeIntro centeredText={true} isHome={false} background={about.introBgType} backgroundImage={about.introBgType} heading={about.introText} backgroundVideo={about.introBgImageVideo} />
           
            <div className="section-1 section-image-text">
              <div className="image-wrapper">
                <GatsbyImage image={getImage(about?.section1Image?.localFile)} className="image" alt={'test'} />
              </div>

              <div className="text-wrapper">
                {parse(about.section1Text)}
              </div>
            </div>
            
            <div className="section-2  section-image-text">
              <div className="image-wrapper">
                <GatsbyImage image={getImage(about?.section2Image?.localFile)} className="image" alt={'test'} />
              </div>
              <div className="text-wrapper">
                {parse(about.section2Text)}
              </div>
            </div>

            <div className="section-3">
              {
                about.section3List.map((list, index) => {
                  return(
                    <div className="listItem" key={`list_item_${index}`}>
                      <div className="title">{list.heading}</div>
                      <div className="text">{parse(list.text)}</div>
                    </div>
                  )
                })
              }
            </div>
        </Styling>
    )
}


export const query = graphql`
  {
    wpPage(title: {eq: "About"}) {
      seo {
        metaDesc
        title
      }
      about_fields {
        introBgImage {
          localFile {
            childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
            }
          }
        }
        introBgImageVideo
        introBgType
        introText
        section1Text
        section1Image {
          localFile {
            childImageSharp {
                gatsbyImageData(
                width: 1500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                )
            }
          }
        }
        section2Text
        section3List {
          heading
          text
        }
        section2Image {
          localFile {
            childImageSharp {
                gatsbyImageData(
                width: 1500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                )
            }
          }
        }
      }
    }
  }
`
